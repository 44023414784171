/* Header */

header {
  border-bottom: 1px solid #eeeeee;
  padding: 2px 25px;
}
.header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.header-column {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 15px;
}
.header-column a {
  color: #000000;
  text-decoration: none;
}
.header-nav-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
}
.header-nav-btn img {
  width: 20px;
  height: 20px;
}
.header-logo {
  height: 85px;
  margin-bottom: -20px;
  margin-top: -20px;
}

/* App Launcher */

.app-launcher {
  opacity: 1;
  width: 350px;
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  background-color: #ffffff;
  z-index: 9999;
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2), 0 3px 6px rgba(0, 0, 0, 0.15);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}
@media screen and (max-width: 400px) {
  .app-launcher {
    width: 100vw;
  }
}
.active {
  opacity: 1;
  transform: translateX(0) !important;
}
.inactive {
  opacity: 1;
  transform: translateX(-400px);
}
#overlay {
  position: fixed;
  display: none;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.5);
  z-index: 10;
  cursor: pointer;
}
.app-launcher-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100vh;
}
.app-launcher-top, .app-launcher-bottom {
  padding: 16px 25px;
}
.app-launcher-top {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
}
.app-launcher-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
}
.app-launcher-header a {
  color: #000000;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  font-weight: 600;
  font-size: 1em;
}
.app-launcher-header i {
  margin-bottom: -1px;
}
.app-launcher-apps {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.app-launcher-apps h1 {
  margin: 0;
  padding: 0;
  font-size: 1.2em;
}
.app {
  border-radius: 5px;
  cursor: pointer;
  padding: 2px;
  width: 75px;
  height: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  transition: 0.3s ease-in-out background-color, transform 0.3s ease-in-out;
}
.app a {
  text-decoration: none;
  color: #000000;
  text-align: center;
}
.app p {
  margin: 0;
  padding: 2px 0;
  font-size: 0.8em;
  height: 15px;
}
.app:hover {
  transform: translateY(-3px);
  background-color: #eeeeee;
}
.see-all-apps-btn {
  color: #000000;
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  font-size: 1em;
}
.see-all-apps-btn:hover, .app-launcher-header a:hover {
  text-decoration: underline;
}
.app-launcher-divider {
  border-top: 1px solid #eeeeee;
  border-bottom: 0;
  border-left: 0;
  border-right: 0;
  margin: 0;
  padding: 0;
}
.app-launcher-nav ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
  gap: 8px;
  margin: 0;
  padding: 0;
}
.app-launcher-nav ul li a {
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 15px;
  text-decoration: none;
  color: #000000;
  font-weight: 600;
  padding: 8px 5px;
}
.app-launcher-nav ul li {
  transition: background-color 0.1s ease-in-out;
  border-radius: 8px;
}
.app-launcher-nav ul li:hover {
  background-color: #eeeeee;
}
.app-launcher-nav ul li a i {
  margin-bottom: -1px;
  width: 17px;
}
.app-launcher-nav {
  width: 100%;
}
.app-launcher-nav button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  font-weight: 600;
  font-size: 1em;
  color: #000000;
  padding: 8px 5px;
}
.app-launcher-bottom {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.app-launcher-footer-nav ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.app-launcher-footer-nav ul li a {
  text-decoration: none;
  color: #000000;
  font-size: 0.7em;
}
.app-launcher-footer-nav ul li a:hover {
  text-decoration: underline;
}
.app-launcher-footer-legal p {
  text-align: center;
  font-size: 0.7em;
}
.apps {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

/* Login (.login) */

.login {
  background-color: rgb(206, 178, 255);;
  /* background: linear-gradient(to top, #ffffff, #d8c3fb); */
  height: 100vh;
}
.login-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  height: 100%;
}
.login-title {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
}
.login-title img {
  height: 100px;
  width: auto;
  margin: -50px -30px;
}
.login-title h1 {
  font-size: 1.6em;
  font-weight: 500;
  margin: 0;
  font-weight: 600;
  text-align: center;
}
.login-form {
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.login-form form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  width: 450px;
}
@media screen and (max-width: 700px) {
  .login-form form {
      width: 350px;
  }
  .login-form-nav a {
      font-size: 0.8em;
  }
}
@media screen and (max-width: 500px) {
  .login-form form {
      width: 280px;
  }
  .login-form {
      padding: 30px;
  }
  .login-form-nav {
      flex-direction: column !important;
      gap: 10px;
  }
}
@media screen and (max-width: 400px) {
  .login-form form {
      width: 220px;
  }
}
.login-form form fieldset {
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0;
}
.login-form form fieldset label {
  font-weight: 600;
}
.login-form form fieldset input {
  width: 100%;
  padding: 12px;
  border-right: none;
  border-top: none;
  border-bottom: none;
  font-size: 0.9em;
  border-left: 1px solid #000000;
}
.login-form form fieldset input:focus {
  outline: none;
}
.login-input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid #000000;
}
.login-input i {
  font-size: 1em;
  color: #000000;
  padding: 12px;
}
.login-form-nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.login-form-nav a {
  color: #2962FF;
}
.login-form-nav a:hover {
  text-decoration: none;
}
.login-main {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.login-products {
  display: grid;
  /* Replace and change for number of product-items */
  grid-template-columns: repeat(1, 40px);
  justify-content: center;
  align-items: center;
  gap: 15px;
}
.login-product {
  background-color: #ffffff;
  padding: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}
.login-product img {
  width: 30px;
  height: 30px;
}
.login-footer p {
  text-align: center;
  margin: 0;
  padding: 0;
}

/* Register (.register) */

.register {
  background-color: rgb(206, 178, 255);
  /* background: linear-gradient(to top, #ffffff, #d8c3fb); */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0;
}
.register-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}
.register-container div {
  width: 100%;
}
.register-title img {
  height: 160px;
  width: auto;
  margin: -50px -30px;
}
.register-title-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  gap: 20px;
}
.register-title-item div:last-child {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.register-title h1 {
  text-align: center;
  margin-bottom: 0;
  font-size: 1.6em;
}
.register-title-item a {
  text-align: right;
  width: 100%;
  color: #2962FF;
}
.register-title-item a:hover {
  text-decoration: none;
}
.register-form {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}
.register-form form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
  width: 100%;
}
@media screen and (max-width: 700px) {
  .register-form {
      padding: 0;
  }
  .register-form form {
      width: 400px;
  }
  .register-form-nav a, .register-form-nav p, .register-footer p {
      font-size: 0.8em;
  }
  .register-form-nav p {
      padding: 0 30px;
  }
  .register-title-item a {
      text-align: center;
  }
  .register-title img {
      width: 100%;
      margin-left: 0;
      margin-right: 0;
  }
  .register-title-item {
      flex-direction: column;
  }
}
@media screen and (max-width: 500px) {
  .register-form form {
      width: 300px;
  }
  .register-form-nav {
      flex-direction: column !important;
      gap: 10px;
  }
}
@media screen and (max-width: 400px) {
  .register-form form {
      width: 250px;
  }
}
.register-form form fieldset {
  width: 100%;
  border: none;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 0;
}
.register-form form fieldset label {
  font-weight: 600;
}
.register-form form fieldset input {
  width: 100%;
  padding: 12px;
  border-right: none;
  border-top: none;
  border-bottom: none;
  font-size: 0.9em;
  border-left: 1px solid #000000;
}
.register-form form fieldset input:focus {
  outline: none;
}
.register-input {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  border: 1px solid #000000;
  background-color: #ffffff;
}
.register-input i {
  font-size: 1em;
  color: #000000;
  padding: 12px;
}
.register-footer p {
  text-align: center;
  margin: 0;
  padding: 0;
}
.register-form-nav {
  text-align: center;
}
.register-form-nav a {
  color: rgb(0,0,255);
}
.register-form-nav a:hover {
  text-decoration: none;
}
.register-input checkbox {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  gap: 20px;
}
.register-input checkbox input {
  width: auto !important;
}
.register-checkbox {
  border: none;
  background-color: transparent;
}

/* Error message */

.error-message {
  display: none;
  position: fixed;
  bottom: 15px;
  right: 15px;
  background-color: red;
  color: white;
  font-size: 1em;
  padding: 8px 12px;
  font-weight: 600;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  gap: 10px;
  justify-content: center;
  align-items: center;
}
.error-message.visible {
  display: flex;
}

/* Home (.home) */

/* Welcome (.welcome) */

.welcome {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 30px;
  padding: 6% 2%;
}
@media screen and (max-width: 942px) {
  .welcome {
    align-items: flex-start;
      flex-direction: column;
  }
  .welcome-column:last-child {
    flex-basis: unset;
  }
  .welcome-column:last-child a {
    padding: 15px !important;
  }
  .welcome-column:last-child h2, .welcome-column:last-child i {
    font-size: 1.2em !important;
  }
}
.welcome-column {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 30px;
}
.welcome-column img {
  height: 100px;
  width: auto;
  margin: -30px -10px;
}
@media screen and (max-width: 500px) {
  .welcome-column img {
      width: 100%;
  }
}
.welcome-column a {
  font-size: 1em;
}
.welcome-column h1 {
  font-size: 2em;
  margin: 0;
  font-weight: 600;
}
@media screen and (max-width: 500px) {
  .welcome-column h1 {
      font-size: 1.6em;
  }
}
.welcome-nav {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}
.welcome-nav a {
  color: #2962FF;
}
.welcome-nav a:hover {
  text-decoration: none;
}
.welcome-column:last-child {
  flex-basis: 30%;
}
@media screen and (max-width: 1193px) {
  .welcome-column:last-child {
      flex-basis: 50%;
  }
}
.welcome-column:last-child a {
  background-color: rgb(206, 178, 255);
  color: #000000;
  text-decoration: none;
  padding: 25px;
  border-radius: 3px;
}
.welcome-column:last-child a:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.welcome-column:last-child h2, .welcome-column:last-child i {
  font-size: 1.6em;
}
.welcome-column:last-child h2 {
  font-weight: 400;
}
.welcome-column:last-child div:last-child {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
}
.home hr {
  border: 1px solid #eeeeee;
  margin: 0;
  padding: 0;
}

/* Quick access (.quick-access) */

.quick-access {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 6% 2%;
}
.quick-apps {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  grid-gap: 20px;
  justify-content: flex-start;
  width: 100%;
}
@media screen and (max-width: 1280px) {
  .quick-apps {
    grid-template-columns: repeat(6, 1fr);
  }
}
@media screen and (max-width: 1120px) {
  .quick-apps {
    grid-template-columns: repeat(5, 1fr);
  }
}
@media screen and (max-width: 908px) {
  .quick-apps {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media screen and (max-width: 727px) {
  .quick-apps {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media screen and (max-width: 535px) {
  .quick-apps {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (max-width: 360px) {
  .quick-apps {
    grid-template-columns: repeat(1, 1fr);
  }
}
.quick-app {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  color: #000000;
  border: 2px solid #eeeeee;
  border-radius: 10px;
  padding: 10px;
  min-width: 100px;
  max-width: 100%;
}
.quick-app:hover {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}
.quick-app img {
  width: 80px;
  height: auto;
}
.quick-app p {
  margin: 0;
}
.quick-access-nav {
  display: flex;
  align-items: center;
  gap: 20px;
  text-decoration: none;
  color: #000000;
  transition: transform 0.3s ease-in-out;
  font-weight: 600;
  margin-top: 10px;
}
.quick-access-nav:hover {
  transform: translateY(-3px);
}
.quick-access-nav a {
  font-size: 1em;
}

/* Apps (.apps-page) */

.apps-page {
  display: flex;
  align-items: center;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  gap: 30px;
  padding: 6% 10%;
}
.apps-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 0 2%;
  width: 100%;
}
.apps-row:last-child p {
  font-size: 1em;
}
.apps-row:last-child a {
  margin-top: 10px;
  color: #2962FF;
}
.apps-row:last-child a:hover {
  text-decoration: none;
}

/* Loading Screen */

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  z-index: 1000;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 1;
}

.spinner {
  position: relative;
  border: 8px solid rgba(0, 0, 0, 0.1);
  border-top: 8px solid #9e67ff;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 1s linear infinite;
  z-index: 2;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}  